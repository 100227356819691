*{
    box-sizing: border-box;
}
.slider-container {
    width: 100%;
    /* border: 10px solid red; */
    position: fixed;
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
  }

  .slider-container .innerDiv{
    width: 100%;
    height: 100%;
  }
  
  .slider-container .innerDiv .slick-list {
    width: 100% !important;
    height: 100% !important;
  }

  .slick-vertical .slick-slide {
    border: none !important;
  }

  .innerDiv div{
    width: 100% !important;
    height: 100% !important;
  }

  .innerDiv div img{
    width: 100% !important;
    height: 100% !important;
  }

  /* .loaderContainer{
    display:flex;
    align-items: center;
    justify-content:center;
    border: 10px solid black;
   } 

  .loaderContainer .innerDiv{
    border: 1px solid green;
  }


  .loader{
    border: 1px solid red;
    transform:rotate(90deg);
  } */

  .loaderContainer{
    display:flex;
    align-items: center;
    justify-content:center;
    height:100vh;
   } 

  .loaderContainer .loaderDiv{
    transform: rotate(90deg);
    width: max-content;
  }

  .loader{
    width: 200px !important;
    height: 200px !important;
  }


  .ControlContainer{
    border: 5px solid red;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px; */
    /* padding: 0px 20px; */
  }
    
  .display .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    /* gap: 2px; */
    /* border: 5px solid black; */
    width:100%;
    height: 100%;
    /* transform: rotateZ(270deg); */
    /* height: 80%; */
    /* transform: rotate(-90deg); */
  }
  
  .grid-item {
    /* height: 200px; */
    width: 100%; 
    overflow: hidden;
    border: 0px;
  }
  
  .grid-item iframe{
    height:100%;
    width:100%;
  }
  
  .grid-item img{
    height:100%;
    width:100%;
  }
  
  
  .control{
   text-align: start;
   padding: 40px ;
   background-color: #e5ecf3;
  }
  

  form{
    background-color:white;
    padding: 20px;
    box-shadow: 0px 0px 5px 5px hsl(210, 18%, 87%);
    border-radius: 5px;
    margin:15px 0px;
  }

  form input{
    width:100%;
    margin: 10px 0px;
  }

  form button{
    background-color: #63C6B2;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    padding: 5px 10px;
    border: none;
  }

  .controlDiv{
    background-color:white;
    padding: 15px 20px;
    box-shadow: 0px 0px 5px 5px hsl(210, 18%, 87%);
    border-radius: 5px;
    margin: 15px 0px;
  }

  .controlDiv button{
    background-color: #63C6B2;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    padding: 5px 10px;
    border: none;
    margin:10px 0px;
    margin-right: 10px;
  }

  .controlDiv .btn2{
    background-color:#F9B113;
  }




  @media only screen and (max-width: 600px) {
    .ControlContainer{
      display: block;
    }
  }